import formatLocale from '@local/lib/helpers/formatLocale';
import { getClient } from '@nintendo-of-america/contentful-api';
import getPageProps from '@local/lib/api/getPageProps';
import { RETAIL_LOCATIONS_TYPES } from '@nintendo-of-america/contentful-api/constants';
import { retailStoreBranchPageParser } from '@nintendo-of-america/contentful-api/parsers';
import { getArticles } from '@local/lib/api';
import { getGraph } from '@nintendo-of-america/graph-api';
import { parseRetailSlug } from '@local/lib/helpers';
import { RETAIL_LOCATIONS } from '@shared/util/constants';
import RetailBranchPage from '@local/components/pages/RetailBranch/RetailBranchPage';
import { cloudinaryUrl } from '@shared/util';
import useMediaWidth, { MEDIA_TYPE } from '@local/lib/hooks/useMediaWidth';

export async function getServerSideProps(context) {
  const { res, locale, query } = context;

  const formattedLocale = formatLocale(locale);

  const contentfulQuery = {
    limit: 1,
    include: 10,
    locale: formattedLocale,
    'fields.slug': query.slug,
    content_type: RETAIL_LOCATIONS_TYPES.BRANCH,
  };
  const response = await getClient().getEntries(contentfulQuery);
  const [entry] = response.items;
  if (!entry) return { notFound: true };

  const graph = getGraph(context);

  const dynamicSegments = parseRetailSlug(query.slug);

  const { data: articles } = await getArticles({
    graph,
    locale,
    res,
    limit: 6,
    tags: [dynamicSegments.locationTag],
  });

  const page = retailStoreBranchPageParser(entry);
  const validLocations = Object.values(RETAIL_LOCATIONS);
  return getPageProps({
    page: {
      ...page,
      location: dynamicSegments.slug,
      showNavAndFooter: true,
      latestArticles: articles?.collection.items || null,
      isValidLocation: validLocations.includes(query.slug),
    },
    meta: {
      title: page?.seo?.title || null,
      description: page?.seo?.description || null,
    },
    analytics: {
      pageType: 'Retail Locations',
      pageName: page?.seo?.title || '',
      pageCategory: 'Retail Locations',
    },
    res,
  });
}

const ANCHOR_PILLS = [
  {
    url: '#location',
    label: 'Location',
  },
  {
    url: '#products',
    label: 'Products',
  },
  {
    url: '#events',
    label: 'Events',
  },
  {
    url: '#faq',
    label: 'FAQ',
  },
];

export default function RetailStoreBranch({ page }) {
  const isTablet = useMediaWidth(MEDIA_TYPE.TABLET);
  const MNSBackground = cloudinaryUrl({
    publicId: isTablet
      ? page?.myNintendoStoreSectionBackground?.primary?.assetPath
      : page?.myNintendoStoreSectionBackground?.secondary?.assetPath ||
        page?.myNintendoStoreSectionBackground?.primary?.assetPath,
  });

  const productsSectionBackground = cloudinaryUrl({
    publicId: isTablet
      ? page.productsSectionBackground?.primary?.assetPath
      : page.productsSectionBackground?.secondary?.assetPath ||
        page.productsSectionBackground?.primary?.assetPath,
  });
  return (
    <RetailBranchPage
      heroImage={page?.heroImage}
      inStoreEventsTitleDecorLeft={
        page?.inStoreEventsTitleDecor?.[0]?.primary?.assetPath
      }
      inStoreEventsTitleDecorRight={
        page?.inStoreEventsTitleDecor?.[1]?.primary?.assetPath
      }
      inStoreEventsTitle={page?.inStoreEventsTitle}
      articles={page?.latestArticles}
      storeName={page?.storeName}
      myNintendoStoreSectionBackground={MNSBackground}
      address={page?.address?.richText}
      phone={page?.phone}
      email={page?.email}
      storeHours={page?.storeHours?.richText}
      storeImages={page?.storeImages}
      myNintendoStoreText={page?.myNintendoStoreText}
      myNintendoStoreCta={page?.myNintendoStoreCta.label}
      socialMediaText={page?.socialMediaText}
      productSectionTitle={page?.productSectionTitle}
      productsSectionBackground={productsSectionBackground}
      carouselSlides={page?.carouselSlides}
      navItems={ANCHOR_PILLS}
      lightText={page?.productsSectionLightText}
      faqHeading={page?.faqHeading}
      faqQuestions={page?.faqQuestions}
      faqSeeAllLink
      location={page?.location}
      legalDisclaimer={page?.legalDisclaimer?.richText}
      banner={page?.banner}
      message={page?.message}
      instagramURL={page?.instagramURL}
      XDotComURL={page?.xDotComURL}
    />
  );
}
